import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Colors } from './helpers';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "color-palette"
    }}>{`Color Palette`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@import '@mfrm/mfcl/variables.scss';
`}</code></pre>
    <Colors mdxType="Colors" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      